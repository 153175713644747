import type {UseFetchOptions} from "#app";

type UseFetchArgs = Parameters<typeof useFetch>

type Url = UseFetchArgs[0]

function useMyFetch<T>(url: Url, opts: UseFetchOptions<T> = {}) {

    const config = useRuntimeConfig();
    const apiBaseUrl = config.public.apiBaseUrl

    return useFetch(url, {
        baseURL: apiBaseUrl,
        onRequest({request, options}) {
            // 设置请求头
            // options.headers = options.headers || {}
            // options.headers.authorization = '...'
        },
        onRequestError({request, options, error}) {
            // 处理请求错误
        },
        onResponse({request, response, options}) {
            // 处理响应数据
            // localStorage.setItem('token', response._data.token)
        },
        onResponseError({request, response, options}) {
            // 处理响应错误
        },
        cache:'no-cache',
        ...opts,
    })
}

export const useHttp = {
    get: <T>(url: Url, opts?: UseFetchOptions<T>) => {
        return useMyFetch<T>(url, {...opts, method: 'get'})
    },
    post: <T>(url: Url, opts?: UseFetchOptions<T>) => {
        return useMyFetch<T>(url, {...opts, method: 'post'})
    },
    put: <T>(url: Url, opts?: UseFetchOptions<T>) => {
        return useMyFetch<T>(url, {...opts, method: 'put'})
    },
    delete: <T>(url: Url, opts?: UseFetchOptions<T>) => {
        return useMyFetch<T>(url, {...opts, method: 'delete'})
    },
}