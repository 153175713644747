import type {Service, ServiceDto} from "~/composables/api";

export function serviceDto2Service(dto: ServiceDto) {
    return {
        id: dto.id,
        agentId: dto.agent_id,
        productId: dto.product_id,
        certificateTypeId: dto.product_id,
        name: dto.name,
        phone: dto.phone,
        code: dto.code,
        provider: dto.provider,
        number: dto.number,
        startTime: dto.start_time,
        exitTime: dto.exit_time,
        img: dto.img,
        carrier: dto.carrier,
        costIntegral: dto.cost_integral,
    } as Service
}